<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.inboundInvoice') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.inboundInvoice') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.invoiceNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.invoiceNumber').toLowerCase()"
                      rules="required"
                      vid="invoiceNumber"
                  >
                    <b-form-input
                        v-model="formData.invoiceNumber"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.invoiceNumber').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.country')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.country').toLowerCase()"
                      rules=""
                      vid="country"
                  >
                    <v-select
                        v-model="formData.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="countryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.supplierTaxNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.supplierTaxNumber').toLowerCase()"
                      :rules="{required, taxNumber:formData.country.value.toUpperCase()}"
                      vid="supplierTaxNumber"
                      ref="taxNumberIsValid"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>
                          {{ formData.country.value.toUpperCase() }}
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="formData.supplierTaxNumber"
                          @input="queryTaxNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.supplierTaxNumber').toLowerCase()"
                          :formatter="taxNumberFormatter"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.supplierName')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.supplierName').toLowerCase()"
                      rules="required"
                      vid="supplierName"
                  >
                    <b-form-input
                        v-model="formData.supplierName"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.supplierName').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.paymentMethod')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.paymentMethod').toLowerCase()"
                      rules="required"
                      vid="paymentMethod"
                  >
                    <v-select
                        v-model="formData.paymentMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="paymentMethodOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.appearance')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.appearance').toLowerCase()"
                      rules="required"
                      vid="appearance"
                  >
                    <v-select
                        v-model="formData.appearance"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="appearanceOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.operation')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.operation').toLowerCase()"
                      rules="required"
                      vid="operation"
                  >
                    <v-select
                        v-model="formData.operation"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="operationOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.category')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.category').toLowerCase()"
                      rules="required"
                      vid="category"
                  >
                    <v-select
                        v-model="formData.category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="categoryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.issueDate')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.issueDate').toLowerCase()"
                      rules="required"
                      vid="issueDate"
                  >
                    <b-form-input
                        v-model="formData.issueDate"
                        type="date"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.issueDate').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.deliveryDate')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.deliveryDate').toLowerCase()"
                      rules="required"
                      vid="deliveryDate"
                  >
                    <b-form-input
                        v-model="formData.deliveryDate"
                        type="date"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.deliveryDate').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.paymentDate')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.paymentDate').toLowerCase()"
                      rules="required"
                      vid="paymentDate"
                  >
                    <b-form-input
                        v-model="formData.paymentDate"
                        type="date"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.paymentDate').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.currency')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.currency').toLowerCase()"
                      rules="required"
                      vid="currency"
                  >
                    <v-select
                        v-model="formData.currency"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="currencyOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.netAmount')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.netAmount').toLowerCase()"
                      rules="required"
                      vid="netAmount"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="formData.netAmount"
                          type="number"
                          step="0.01"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.netAmount').toLowerCase()"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          {{ formData.currency.sign }}
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.vatAmount')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.vatAmount').toLowerCase()"
                      rules="required"
                      vid="vatAmount"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="formData.vatAmount"
                          type="number"
                          step="0.01"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.vatAmount').toLowerCase()"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          {{ formData.currency.sign }}
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.netAmountHUF')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.netAmountHUF').toLowerCase()"
                      rules="required"
                      vid="netAmountHUF"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="formData.netAmountHUF"
                          type="number"
                          step="1"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.netAmountHUF').toLowerCase()"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          Ft
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.vatAmountHUF')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.vatAmountHUF').toLowerCase()"
                      rules="required"
                      vid="vatAmountHUF"
                  >
                    <b-input-group>
                      <b-form-input
                          v-model="formData.vatAmountHUF"
                          type="number"
                          step="1"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.vatAmountHUF').toLowerCase()"
                      />
                      <b-input-group-append>
                        <b-input-group-text>
                          Ft
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay,
  BInputGroup, BInputGroupPrepend, BInputGroupText, BInputGroupAppend
} from 'bootstrap-vue'
import {
  required, taxNumber
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import {formatTaxNumber} from "@core/utils/filter"
import i18nHelper from "@/libs/helper/i18nHelper";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'invoice',
      formData: {
        invoiceNumber: '',
        supplierName: '',
        supplierTaxNumber: '',
        issueDate: '',
        deliveryDate: '',
        paymentDate: '',
        netAmount: '',
        netAmountHUF: '',
        vatAmount: '',
        vatAmountHUF: '',
        country: {
          value: '',
          title: ''
        },
        paymentMethod: {
          value: 'transfer',
          title: this.$t('invoices.paymentMethods.transfer')
        },
        appearance: {
          value: 'electronic',
          title: this.$t('invoices.appearances.electronic')
        },
        operation: {
          value: 'create',
          title: this.$t('invoices.operations.create')
        },
        category: {
          value: 'normal',
          title: this.$t('invoices.categories.normal')
        },
        currency: {
          sign: '€',
          value: 'EUR',
          title: 'EUR'
        },
        direction: 'INBOUND'
      },
      countryOptions: [],
      paymentMethodOptions: [
        {
          value: 'transfer',
          title: this.$t('invoices.paymentMethods.transfer')
        },
        {
          value: 'cash',
          title: this.$t('invoices.paymentMethods.cash')
        },
        {
          value: 'card',
          title: this.$t('invoices.paymentMethods.card')
        },
        {
          value: 'voucher',
          title: this.$t('invoices.paymentMethods.voucher')
        },
        {
          value: 'other',
          title: this.$t('invoices.paymentMethods.other')
        }
      ],
      appearanceOptions: [
        {
          value: 'paper',
          title: this.$t('invoices.appearances.paper')
        },
        {
          value: 'electronic',
          title: this.$t('invoices.appearances.electronic')
        },
        {
          value: 'edi',
          title: this.$t('invoices.appearances.edi')
        },
        {
          value: 'unknown',
          title: this.$t('invoices.appearances.unknown')
        }
      ],
      operationOptions: [
        {
          value: 'create',
          title: this.$t('invoices.operations.create')
        },
        {
          value: 'modify',
          title: this.$t('invoices.operations.modify')
        },
        {
          value: 'storno',
          title: this.$t('invoices.operations.storno')
        },
      ],
      categoryOptions: [
        {
          value: 'normal',
          title: this.$t('invoices.categories.normal')
        },
        {
          value: 'simplified',
          title: this.$t('invoices.categories.simplified')
        },
        {
          value: 'aggregate',
          title: this.$t('invoices.categories.aggregate')
        },
      ],
      currencyOptions: [
        {
          sign: '€',
          value: 'EUR',
          title: 'EUR',
        },
        {
          sign: '$',
          value: 'USD',
          title: 'USD',
        }
      ],
      loading: false,
      required,
      taxNumber,
      formatTaxNumber
    }
  },
  created() {
    this.loading = true

    this.$store.dispatch('fetchCountriesForSelect').then(response => {
      this.countryOptions = response

      if (this.countryOptions.length) {
        this.formData.country = {
          value: 'IE',
          title: i18nHelper.localizeCountry('IE')
        }
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.paymentMethod = this.formData.paymentMethod.value
          fd.appearance = this.formData.appearance.value
          fd.operation = this.formData.operation.value
          fd.category = this.formData.category.value
          fd.currency = this.formData.currency.value
          fd.client = this.$route.params.id

          this.$store.dispatch('storeInvoice', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    queryTaxNumber() {
      this.$refs.taxNumberIsValid.validate().then(success => {
        if (success.valid) {
          this.loading = true
          let actionName
          let isoCode
          if (this.formData.country.value === 'HU') {
            actionName = 'getTaxNumberHu'
            isoCode = ''
          } else {
            actionName = 'getTaxNumberEu';
            isoCode = this.formData.country.value.toUpperCase()
          }

          this.$store.dispatch(actionName, {'taxNumber': isoCode + this.formData.supplierTaxNumber}).then(response => {
            let taxpayer = response.data.taxpayer

            this.formData.supplierName = taxpayer.name
            this.formData.supplierTaxNumber = taxpayer.taxNumber

          }).catch(error => {
            if (error.response.status === 422) {
              this.clearAddressData()
              if (Object.keys(error.response.data).includes('errors')) {
                console.log(error.response.data.errors)
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else {
                // TODO: set errors!
                this.$helpers.showErrorToast(error.response.data.message)
              }
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    clearAddressData() {
      this.formData.supplierName = ''
    },
    taxNumberFormatter(value) {
      const countryCode = this.formData.country.value.toUpperCase()

      return formatTaxNumber(value, countryCode)
    }
  },
}
</script>
